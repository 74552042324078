import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/lose-weight.jpg'
import styles from './post-grid.module.css'

const LoseWeight = () => {
    return (
        <div className={styles.wrapper}>
            <SEO
                title={'Zhubněte s námi do plavek'}
                description={
                    'Letní sezóna se pomalu blíží a s ní přichází i touha vypadat skvěle v plavkách. Pokud je to i váš případ a hledáte efektivní a udržitelný způsob, jak do léta zhubnout, může být krabičková dieta to pravé pro vás.'
                }
            />
            <Container>
                <Breadcrumbs style={{ margin: '24px 0' }}>
                    <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
                    <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
                    <BreadcrumbsItem link="/blog/lose-weight">
                        Zhubněte s námi do plavek
                    </BreadcrumbsItem>
                </Breadcrumbs>
            </Container>
            <Container>
                <div className={styles.postGrid}>
                    <div>
                        <Hero
                            title="Zhubněte s námi do plavek"
                            date="23.05.2023"
                        />
                        <div>
                            <p className={styles.postText}>
                                Letní sezóna se pomalu blíží a s ní přichází i touha <b>vypadat skvěle v plavkách</b>. Pokud je to i váš případ a hledáte <b>efektivní a udržitelný způsob, jak do léta zhubnout</b>, může být  <a
                                    href="https://nutritionpro.cz"
                                    target="_blank"
                                >
                                    krabičková dieta
                                </a>  to pravé pro vás.
                            </p>
                            <p className={styles.postText}>
                                Krabičková dieta je moderní trend v oblasti stravování, který nabízí <b>snadný a pohodlný způsob, jak zhubnout</b> bez nutnosti složitého plánování jídel a únavného počítání kalorií. Díky <a
                                    href="https://nutritionpro.cz"
                                    target="_blank"
                                >
                                    našim krabičkám
                                </a> se můžete dostat do formy a připravit se na <b>léto plné sebevědomí!</b>
                            </p>

                            <img
                                src={img1}
                                className={styles.postImg}
                                style={{ marginBottom: '40px' }}
                                alt="jídlo v krabicích"
                            />

                            <h5 className={styles.postTitle}>Krabičková dieta a hubnutí</h5>


                            <p className={styles.postText}>
                                <a href="https://nutritionpro.cz"
                                    target="_blank">
                                    Krabičková dieta
                                </a>
                                {' '} je způsob stravování, při kterém si <b>objednáte připravená jídla</b> v krabičkách, které jsou vám <b>pravidelně doručovány</b>. Jídla jsou <b>připravena čerstvě a na míru vašemu cíli</b>, v tomto případě hubnutí. Porce jsou navrženy tak, aby <b>přesně odpovídaly vašim potřebám</b>, pokud tedy zvolíte program na hubnutí, budete <b>v lehkém kalorickém deficitu</b>, jídla však budou obsahovat dostatečné množství všech živin, tedy <b>bílkovin, sacharidů i zdravých tuků.</b> Také se nemusíte bát nedostatku vitaminů a minerálních látek.

                            </p>
                            <h5 className={styles.postTitle}>Jak funguje naše krabičková dieta?
                            </h5>
                            <p className={styles.postText}>Krabičková dieta je <b>velmi jednoduchá a praktická</b>. Stačí si zvolit program, který vám nejvíce vyhovuje, tedy například hubnoucí program. Poté si vyberete počet jídel, které budete denně konzumovat a délku trvání stravování. Krabičky s jídlem vám budou pravidelně doručovány až k vám domů, takže se <b>nemusíte starat o nákup potravin a přípravu jídel</b>. Díky této <b>časové úspoře</b> se <b>můžete více zaměřit na plnění vašeho cíle</b>, takže si můžete místo vaření jít třeba zacvičit, na procházku nebo se pořádně protáhnout.</p>

                            <h5 className={styles.postTitle}>Jak zhubnout do plavek</h5>
                            <p className={styles.postText}>Při odebírání našich krabiček se <b>o stravu starat nemusíte</b>, to za vás vyřešíme my! Kromě zdravého a vyváženého jídelníčku je také dobré začít s <b>pravidelnou fyzickou aktivitou</b>. Který pohyb je na hubnutí nejlepší, se můžete dozvědět v <a href="https://nutritionpro.cz/blog/s-jakym-sportem-nejrychleji-zhubnu/"
                                target="_blank">tomto článku</a>. Myslete také na dostatečný <a href='https://nutritionpro.cz/blog/post-3/' target='_blank'>pitný režim</a>. Ten by měl být tvořen ideálně čistou vodou, případně neslazenými čaji. Dále myslete na <b>dostatek spánku</b>, který je pro hubnutí též zásadní. Myslete také na svou <b>psychickou pohodu</b> a snažte se radovat z maličkostí a pokud je to možné, eliminujte stresové spouštěče. Čistá hlava je pro hubnutí velmi důležitá. </p>

                            <p className={styles.postText}><b>TIP:</b> Nedaří se vám zhubnout? <a target='_blank' href='https://nutritionpro.cz/blog/proc-se-vam-nedari-zhubnout/'>Zde</a> je několik možných důvodů proč.
                            </p>
                            <p className={styles.postText}> <a target='_blank' href='https://nutritionpro.cz/'>Naše krabičková dieta</a> může být i vaším <b>skvělým pomocníkem</b> na cestě za <b>vysněnou postavou</b>. Vyzkoušejte a uvidíte, že už brzy budete plánovat dovolenou s nadšením, díky nově <b>nabytému sebevědomí</b> a zdravému, fit tělu!
                            </p>
                        </div>
                    </div>
                    <PostSidebar />
                </div>
            </Container>
        </div>
    )
}

export default LoseWeight
